@import '~bloko/common/styles/text-base';
@import '~bloko/common/styles/rounded-corner-base';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

@article-item-height: 165px;
@article-item-height-l: 220px;

.preview {
    border-radius: @rounded-corner-default;
    width: 68px;
    height: 68px;
    object-fit: cover;
    display: block;
}

.content {
    display: block;

    @media @screen-gt-xs {
        display: flex;
    }
}

.image-wrapper {
    flex-shrink: 0;
    align-self: center;
}

.image-medium-wrapper {
    align-self: start;
}

.caption-wrapper {
    display: block;
    padding: 0 15px;

    @media @screen-gt-xs {
        padding: 0;
        display: inline-block;
    }
}

.overlay {
    background-color: fadeout(@color-special-absolute-black, 50%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 1;
}

.date {
    color: @color-gray-60;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-size: @_font-size-small;
}

.medium {
    display: flex;
    align-items: center;

    .caption {
        margin-left: 18px;
    }
}

.large {
    .content {
        margin: 0 -15px;
    }

    .preview {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 300ms linear;

        &:hover {
            height: 110%;
            width: 110%;
        }
    }

    .preview-container {
        border-radius: @rounded-corner-default;
        height: @article-item-height;
        overflow: hidden;

        @media @screen-gt-m {
            height: @article-item-height-l;
        }
    }

    .caption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        min-height: @article-item-height;

        @media @screen-gt-m {
            min-height: @article-item-height-l;
        }

        @media @screen-lt-s {
            min-height: auto;
        }
    }

    .caption-info {
        color: @color-gray-60;
    }

    .caption-views {
        .caption-info;

        /* stylelint-disable selector-max-compound-selectors */
        svg > path {
            stroke: @color-gray-60;
        }
    }
}

.special {
    position: relative;
    box-sizing: border-box;
    height: 290px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: @rounded-corner-default;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    transition: background-size 0.2s linear;
    padding: 27px 20px;

    .caption {
        position: relative;
        /* stylelint-disable-next-line declaration-property-value-allowed-list */
        z-index: 2;
    }

    &:hover {
        background-size: auto 110%;
    }
}

.promo {
    .special;

    background-size: cover;
    height: auto;
    min-height: 220px;

    &:hover {
        background-size: cover;
    }

    @media @screen-gt-xs {
        background-size: auto 120%;

        &:hover {
            background-size: auto 130%;
        }
    }

    @media @screen-gt-s {
        min-height: 200px;
    }
}

.label {
    position: relative;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 2;

    > div {
        cursor: pointer;
    }
}

.data {
    display: flex;
    align-items: center;
}
